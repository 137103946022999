import AbstractReduxAction from "../actions/AbstractReduxAction";
import { Action, MiddlewareAPI, Dispatch } from "redux";
import ReduxState from "../ReduxState";

export default (_store: MiddlewareAPI<Dispatch, ReduxState>) => (next: Dispatch<Action>) => (action: Action) => 
{
    console.log("Redux Reducer - " + action.constructor.name);

    if (action instanceof AbstractReduxAction)
    {   action = action.toPlainObject(); }

    return next(action);
}
