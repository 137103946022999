import ReduxConfigurationEntity from '../entities/ReduxConfigurationEntity';
import AbstractReduxAction from "./AbstractReduxAction";

export default class OnConfigurationLoaded extends AbstractReduxAction
{
    public static readonly TYPE = "on_configuration_loaded";

    public configuration: ReduxConfigurationEntity;

    constructor(configuration: ReduxConfigurationEntity)
    {
        super(OnConfigurationLoaded.TYPE);

        this.configuration = configuration;
    }
}